import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { CONDUCTRICS_GOALS } from '../../../helpers/constants';
import { generateUrl } from '../../../helpers/utils/client';
import type { TPoliciesList } from './PoliciesList';
import PoliciesList from './PoliciesList';
import {
  ResortPolicy,
  ParkingPolicy,
  PetsPolicy,
  SmokingPolicy,
  WifiPolicy,
} from './HotelPoliciesTabContent';
import { BrandLink } from '../../brand-buttons/brand-buttons';
import { useTheme } from '../../../hooks/use-theme';

export type THotelPolicies = {
  policy: NonNullable<GetHotelHomePageQuery['hotel']>['policy'] | null;
  resortPolicyText?: string | null;
  petsInfo: NonNullable<GetHotelHomePageQuery['hotel']>['pets'] | null;
  safetyInfo: NonNullable<GetHotelHomePageQuery['hotel']>['safetyAndSecurity'] | null;
  facilityInfo: NonNullable<GetHotelHomePageQuery['hotel']>['facilityOverview'] | null;
};

export const AmenityType = {
  Parking: 'parking',
  Pets: 'pet-friendly',
  NonSmoking: 'no-smoking',
  WiFi: 'wifi',
  FeesAndCharges: 'currency',
  Resort: 'resort',
} as const;

/**
 * Note: This is the version of the hotel's policies shown on the property's
 * homepage. Compare to the `hotel-location` and `hotel-info` page components.
 */
const HotelPolicies = ({
  policy,
  resortPolicyText,
  safetyInfo,
  petsInfo,
  facilityInfo,
}: THotelPolicies) => {
  const { t } = useTranslation(['hotel-policies']);
  const { theme } = useTheme();
  const { relativeUrl } = generateUrl(useRouter(), 'hotel-info');

  const getPolicyContent = (
    key: string,
    policy: THotelPolicies['policy'],
    petsInfo: THotelPolicies['petsInfo'],
    safetyInfo: THotelPolicies['safetyInfo']
  ) => {
    const policyContentMap: Record<string, React.JSX.Element> = {
      parking: <ParkingPolicy policy={policy} />,
      pets: <PetsPolicy petsInfo={petsInfo} />,
      smoking: <SmokingPolicy policy={policy} safetyInfo={safetyInfo} />,
      wifi: <WifiPolicy policy={policy} />,
    };

    return policyContentMap[key] || null;
  };

  const items: TPoliciesList['items'] = [
    {
      description: getPolicyContent('parking', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:parking'),
      icon: AmenityType.Parking,
    },
    {
      description: getPolicyContent('pets', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:pets'),
      icon: AmenityType.Pets,
    },
    {
      description: getPolicyContent('smoking', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:smoking'),
      icon: AmenityType.NonSmoking,
    },
  ];

  const wlanInRoomFeeIsNull = policy?.wifi?.wlanInRoomFee === null;
  if (!wlanInRoomFeeIsNull) {
    items.push({
      description: getPolicyContent('wifi', policy, petsInfo, safetyInfo),
      name: t('wiFi'),
      icon: AmenityType.WiFi,
    });
  }

  if (resortPolicyText) {
    const isResort = !!facilityInfo && facilityInfo.isWorldwideResort;
    const resortFeePolicies = {
      description: <ResortPolicy resortPolicyText={resortPolicyText} />,
      icon: isResort ? AmenityType.Resort : AmenityType.FeesAndCharges,
      name: isResort ? t('hotel-policies:resort') : t('hotel-policies:feesAndCharges'),
    };

    items.unshift(resortFeePolicies);
  }

  return (
    <div className="my-0 mt-4 flex flex-auto flex-col justify-center py-4 pb-0">
      <h2
        className={cx('heading-2xl lg:heading-3xl pb-4 text-center', {
          'accent-heading': theme.accent?.heading,
        })}
        data-testid="icon-grid-header"
      >
        {t('hotelPolicies')}
      </h2>
      <PoliciesList items={items} />
      <div className="flex justify-center py-6">
        <BrandLink
          url={relativeUrl}
          anchorClassName="my-2"
          label={t('hotel-policies:allPolicies')}
          onClick={async () => {
            await sendRewardAsync(CONDUCTRICS_GOALS.ALL_POLICIES_CTA_ENGAGEMENT);
          }}
        />
      </div>
    </div>
  );
};

export default HotelPolicies;
