import { useState } from 'react';
import cx from 'classnames';

import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import Icon from '@dx-ui/osc-icon';

import { useTheme } from '../../../hooks/use-theme';

import type { TAmenitiesData } from './Policies.types';

export type TPoliciesList = {
  items: TAmenitiesData[];
};

const PoliciesList = ({ items }: TPoliciesList) => {
  const { theme } = useTheme();
  const defaultTab = items.length ? getTabId(0) : '';
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (!items.length) return null;

  return (
    <Tabs
      changeWithArrow
      className="container h-full"
      defaultActive={defaultTab}
      onTabChange={(id) => {
        setSelectedTab(id || '');
      }}
    >
      <TabList className="border-b">
        {items.map((tab, index) => {
          const tabId = getTabId(index);
          return (
            <TabListButton
              activeClassName={cx('border-b-border text-accent border-b', {
                '!border-b-accent': theme.accent?.border,
                'hover:!accent-icon focus:!text-accent': theme.accent?.hover,
              })}
              inactiveClassName={cx({ 'hover:!accent-icon': theme.accent?.hover })}
              id={tabId}
              key={tabId}
            >
              <div className="flex min-w-[120px] flex-col items-center">
                <Icon
                  className={cx({
                    hidden: tabId === selectedTab,
                  })}
                  name={tab.icon}
                  size="2xl"
                />
                <Icon
                  className={cx({
                    hidden: tabId !== selectedTab,
                  })}
                  name={tab.icon}
                  size="2xl"
                  variant="solid"
                />
                <p
                  className={cx('py-4 text-lg', {
                    'font-bold': tabId === selectedTab,
                    'font-medium': tabId !== selectedTab,
                  })}
                >
                  {tab.name}
                </p>
              </div>
            </TabListButton>
          );
        })}
      </TabList>
      <TabPanels className={theme.childTheme?.base}>
        {items.map((tab, index) => {
          const tabId = getTabId(index);
          return (
            <TabPanel id={tabId} key={tabId} data-testid={`test-tab-panel-${tab.name}`}>
              <div className="mb-4 w-full px-8 py-6">{tab.description}</div>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

function getTabId(index: number) {
  return `policies-tab-${index}`;
}

export default PoliciesList;
